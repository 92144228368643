import { createContext, useContext, useState } from 'react'

export const BigSpinnerIconContext = createContext()

export const useBigSpinnerIcon = () => useContext(BigSpinnerIconContext)

function BigSpinnerIconProvider({ children }) {

  const [isBigSpinnerShowing, setIsBigSpinnerShowing] = useState(false)

  return (
    <BigSpinnerIconContext.Provider value={{ isBigSpinnerShowing, setIsBigSpinnerShowing }}>
      {children}
    </BigSpinnerIconContext.Provider>
  )
}

export default BigSpinnerIconProvider
