import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { useScheduler } from './scheduler';
import service from '../service'
import { useAuth } from './auth';
import {
  startOfToday,
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
  addMonths,
} from 'date-fns'

const JobDetailsModalContext = createContext()

const JobDetailsModalContextProvider = props => {
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [selectedJobDetailsTab, setSelectedJobDetailsTab] = useState('Job Details')
  const [nestedModalOpen, setNestedModalOpen] = useState(true)
  const [locations, setLocations] = useState([]);
  const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [showCostBreakdownWarning, setShowCostBreakdownWarning] = useState({ show: false, continueFunction: {} });
  const [showResetWarning, setShowResetWarning] = useState(false)
  const isCostBreakdownChange = useRef(false)
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileFormData, setFileFormData] = useState(null);
  const [detailsTabEditing, setDetailsTabEditing] = useState(false);
  const [isFlash, setIsFlash] = useState(false)
  const [selectedCalendarJobId, setSelectedCalendarJobId] = useState(null)
  const hiddenFileInput = useRef(null)
  const [detailsTabErrorMessages, setDetailsTabErrorMessages] = useState([])
  const [updatedJob, setUpdatedJob] = useState({});
  const [updatedPool, setUpdatedPool] = useState({});
  const [updatedCustomer, setUpdatedCustomer] = useState({});

  let sentenceCaseRole = "";

  const { me } = useAuth()
  const { selectedJobId, selectedJobNotes, setSelectedJobNotes, getSelectedJobNotes, selectedScheduler, setSelectedScheduler } = useScheduler()

  const handleCancelDetailsTab = () => {
    setFileFormData(null)
    setUploadedFile(null)
    setDetailsTabEditing(false)
  }

  const handleSaveDetailsTab = async ({ updatedJob, updatedPool, updatedCustomer }) => {
    // Validation
    if (updatedJob.jobName === "") {
      setDetailsTabErrorMessages((prevMessages) => {
        const filteredMessages = prevMessages.filter((msg) => !msg.jobName);
        return updatedJob.jobName === ""
          ? [...filteredMessages, { jobName: "Job name must have a value" }]
          : filteredMessages;
      });
      // Don't allow submit
      return
    }

    let newPool
    if (fileFormData) {
      newPool = await service.updatePoolPlan(fileFormData)
    }

    const { returnedJob } = await service.updateJobFromJobDetailsModal({
      jobId: selectedJobId,
      jobDetails: updatedJob,
      poolDetails: {
        ...updatedPool,
        planUri: fileFormData ? newPool.planUri : updatedPool.planUri
      },
      customerDetails: updatedCustomer,
      performedById: me.id, //used for activity log
    });

    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === returnedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          {
            ...selectedScheduler.location.jobs[selectedJobIndex],
            ...returnedJob
          },
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
    setDetailsTabEditing(false);
    setDetailsTabErrorMessages([])
    setUploadedFile(null)
    setFileFormData(null)
  };

  const savePlanToState = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('plan', file);

    const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId);
    const pool = selectedJob.pools[0];

    formData.append('poolId', pool.id);
    formData.append('jobId', selectedJobId);

    setUploadedFile(file);
    setFileFormData(formData);
  };

  const getLocationsAsync = async () => {
    const res = await service.getLocations()
    const data = await res.json()
    setLocations(data)
  }

  const handleCloseJobDetailsModal = () => {
    if (nestedModalOpen) { return; }

    setIsAnimatingOut(true);
    setTimeout(() => {
      setShowJobDetailsModal(false);
      setShowMiniCalendar(false);
      setDetailsTabEditing(false)
    }, 300);
  }

  // MiniCalendar date functions

  const today = startOfToday() //date object
  const [currentDay, setCurrentDay] = useState(today) //date object

  const setDays = () => {
    return eachDayOfInterval({
      start: startOfMonth(currentDay),
      end: endOfMonth(currentDay)
    })
  }

  let days = setDays() //array of date objects running through the current month

  const previousMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const nextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }

  const togglePinNote = async (note) => {
    if (note.pinnedAt === null) {
      const now = new Date();
      const updatedNotes = selectedJobNotes.map(selectedNote =>
        selectedNote === note ? {
          ...selectedNote,
          pinnedAt: now
        } : selectedNote
      );
      setSelectedJobNotes(updatedNotes);
      note.pinnedAt = now;
      const res = await service.pinOrUnpinNote(note)
      const data = await res.json();
    } else if (note.pinnedAt) {
      const updatedNotes = selectedJobNotes.map(selectedNote =>
        selectedNote === note ? {
          ...selectedNote,
          pinnedAt: null
        } : selectedNote
      );
      setSelectedJobNotes(updatedNotes);
      note.pinnedAt = null;
      const res = await service.pinOrUnpinNote(note);
      const data = await res.json();
    }
  }

  const toggleShowTooltipMenu = (noteIndex) => {
    setSelectedJobNotes(prevSelectedJobNotes => {
      const updatedNotes = prevSelectedJobNotes.map((note, index) => {
        if (index === noteIndex) {
          return {
            ...note,
            showTooltip: !note.showTooltip,
          }
        } else {
          return {
            ...note,
            showTooltip: false
          }
        }
      });
      return updatedNotes;
    })
  }

  const toggleShowDeleteConfirmation = (noteIndex) => {
    setSelectedJobNotes(prevSelectedJobNotes => {
      const updatedNotes = prevSelectedJobNotes.map((note, index) => {
        if (index === noteIndex) {
          return {
            ...note,
            showDelete: !note.showDelete,
            showEdit: false
          };
        } else {
          return {
            ...note,
            showDelete: false,
            showEdit: false
          }
        }
      });
      return updatedNotes;
    });
  };

  const toggleShowEditNoteInput = (noteIndex) => {
    setSelectedJobNotes(prevSelectedJobNotes => {
      const updatedNotes = prevSelectedJobNotes.map((note, index) => {
        if (index === noteIndex) {
          return {
            ...note,
            showEdit: !note.showEdit,
            showDelete: false
          };
        } else {
          return {
            ...note,
            showEdit: false,
            showDelete: false
          }
        }
      });
      return updatedNotes;
    });
  };

  const convertRoleToSentenceCase = () => {
    for (let i = 0; i < me?.role?.length; i++) {
      if (i === 0) {
        sentenceCaseRole += me.role[i];
      } else if (i > 0 && i < me.role.length - 1) {
        sentenceCaseRole += me.role[i].toLowerCase();
      } else if (i === me.role.length - 1) {
        sentenceCaseRole += me.role[i].toLowerCase();
        return sentenceCaseRole;
      }
    }
  }
  let sentenceCaseResult = convertRoleToSentenceCase();

  const getTimeElapsedForNotes = (isoDate) => {
    const now = new Date();
    const createdAtDate = new Date(isoDate);
    const timeDiff = now.getTime() - createdAtDate.getTime();
    let daysElapsed = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    daysElapsed -= 1;
    const formattedDate = `${daysElapsed} ${daysElapsed === 1 ? 'day ago' : 'days ago'}`
    return formattedDate
  }

  const createJobNote = async (note) => {
    const noteJobId = note.get('jobId');
    await service.createJobNote(note);
    getSelectedJobNotes(noteJobId)
  }

  const saveUpdatedJobNote = async (note) => {
    const res = await service.updateJobNote(note);
    const data = await res.json();

    setSelectedJobNotes(prevSelectedJobNotes => {
      const updatedJobNotes = prevSelectedJobNotes.map(selectedNote =>
        selectedNote === note ? {
          ...selectedNote,
          body: note.body
        } : selectedNote
      );
      return updatedJobNotes;
    });
  };

  const deleteJobNote = async (note) => {
    const res = await service.deleteJobNote(note);
    const data = await res.json();

    if (res.ok) {
      setSelectedJobNotes(prevSelectedJobNotes => {
        const updatedJobNotes = prevSelectedJobNotes.map(selectedNote =>
          selectedNote === note ? {
            ...selectedNote,
            deletedAt: data.deletedAt
          } : selectedNote
        );
        return updatedJobNotes.filter(note => note.deletedAt === null);
      });
    }
  };


  // Global + Location rates for cost breakdown
  const [globalRates, setGlobalRates] = useState([])
  const [locationRates, setLocationRates] = useState([])

  // Pulls rates from db and sets the above variables
  async function refreshRate() {
    const res = await service.readRate()
    if (res.ok) {
      const tempRate = await res.json();
      setGlobalRates(tempRate)
    }
    const locationId = parseInt(selectedScheduler?.location?.id)
    if (locationId) {
      const res = await service.readLocationRate(locationId)
      if (res.ok) {
        // if there was none for this location, then we use the global rate
        if (res.status === 204) {
          // maybe set a variable to show there are no location rates?
        } else {
          const tempLocationRate = await res.json();
          setLocationRates(tempLocationRate)
        }
      }
    }
  }

  // Populating default object with location rates / global rates
  const insuranceRate = (locationRates?.locationRateInvoice?.insuranceRate ? locationRates?.locationRateInvoice?.insuranceRate : globalRates?.rateInvoice?.insuranceRate)

  const DEFAULT_BREAKDOWN = {
    costBreakdown: {
      jobId: null,
      taxRate: (locationRates?.locationRateInvoice?.crewTaxRate ? locationRates?.locationRateInvoice?.crewTaxRate / 100 : globalRates?.rateInvoice?.crewTaxRate / 100) || 0,
      insurance: insuranceRate || 0,
      batches: 0,
      discountPercent: 0
    },
    invoice: {
      items: [],
      total: 0
    },
    crew: {
      items: [],
      total: {
        total: 0,
        withTax: 0
      }
    },
    drivers: {
      items: [],
      total: {
        total: 0,
        withTax: 0
      }
    },
    material: {
      items: [],
      total: 0
    },
    fuel: {
      items: [],
      total: 0
    }
  }

  const DEFAULT_INVOICE_ITEM = {
    name: '',
    description: '',
    amount: 0,
    amountForMinimum: 0,
    price: 0,
    manufacturer: '',
    minIA: 0,
    minPrice: 0,
    tier: 0,
    color: ''
  }

  const DEFAULT_CREW = {
    name: '',
    role: '',
    pools: 0,
    poolRate: 0,
    gunitePoolRate: 0,
    plasterPoolRate: 0,
    quartzPoolRate: 0,
    pebblePoolRate: 0,
    travel: 0,
    extras: 0,
    extrasReason: '',
    isContractor: false,
    contractorService: '',
    contractorPrice: 0,
    contractorAmount: 1
  }

  const DEFAULT_DRIVER = {
    name: '',
    pools: 0,
    poolRate: 0,
    travel: 0,
    extras: 0,
    extrasReason: ''
  }

  const DEFAULT_MATERIAL_GUNITE = [
    {
      name: 'Sand',
      yards: 0,
      tons: 0,
      costTon: 0,
      costYard: (locationRates?.locationRateGuniteMaterial?.sandCostPerYard ? locationRates?.locationRateGuniteMaterial?.sandCostPerYard : globalRates?.rateGuniteMaterial?.sandCostPerYard) || 34.98
    },
    {
      name: 'Cement',
      yards: 0,
      tons: 0,
      costTon: 0,
      costYard: (locationRates?.locationRateGuniteMaterial?.cementCostPerYard ? locationRates?.locationRateGuniteMaterial?.cementCostPerYard : globalRates?.rateGuniteMaterial?.cementCostPerYard) || 43.12
    },
  ]

  const DEFAULT_MATERIAL_PLASTER = []

  const crewTruckCostGallon = (locationRates?.locationRateGuniteFuel?.crewTruckCostPerGallon || globalRates?.rateGuniteFuel?.crewTruckCostPerGallon) || 0
  const crewTruckMPG = (locationRates?.locationRateGuniteFuel?.crewTruckMilesPerGallon || globalRates?.rateGuniteFuel?.crewTruckMilesPerGallon) || 0
  const crewTruckCostMile = crewTruckMPG > 0 ? (crewTruckCostGallon / crewTruckMPG) : 0

  const mixersCostGallon = (locationRates?.locationRateGuniteFuel?.mobileMixersCostPerGallon || globalRates?.rateGuniteFuel?.mobileMixersCostPerGallon) || 0
  const mixersMPG = (locationRates?.locationRateGuniteFuel?.mobileMixersMilesPerGallon || globalRates?.rateGuniteFuel?.mobileMixersMilesPerGallon) || 0
  const mixersCostMile = mixersMPG > 0 ? (mixersCostGallon / mixersMPG) : 0

  const compressorClearCostGallon = (locationRates?.locationRateGuniteFuel?.compressorClearCostPerGallon || globalRates?.rateGuniteFuel?.compressorClearCostPerGallon) || 0
  const compressorClearMPG = (locationRates?.locationRateGuniteFuel?.compressorClearMilesPerGallon || globalRates?.rateGuniteFuel?.compressorClearMilesPerGallon) || 0
  const compressorClearCostMile = compressorClearMPG > 0 ? (compressorClearCostGallon / compressorClearMPG) : 0

  const DEFAULT_FUEL_GUNITE = [
    {
      name: 'Crew truck',
      trips: 1,
      milesToJob: 0,
      costMile: crewTruckCostMile,
      costGallon: crewTruckCostGallon,
      mpg: crewTruckMPG,
      yards: 0,
      gallonsPerYard: 0
    },
    {
      name: 'Mobile mixers',
      trips: 0,
      milesToJob: 0,
      costMile: mixersCostMile,
      costGallon: mixersCostGallon,
      mpg: mixersMPG,
      yards: 0,
      gallonsPerYard: 0
    },
    {
      name: 'Compressor clear',
      trips: 1,
      milesToJob: 0,
      costMile: compressorClearCostMile,
      costGallon: compressorClearCostGallon,
      mpg: compressorClearMPG,
      yards: 0,
      gallonsPerYard: 0
    },
    {
      name: 'Compressor red',
      trips: 0,
      milesToJob: 0,
      costMile: 0,
      costGallon: (locationRates?.locationRateGuniteFuel?.compressorRedCostPerGallon ? locationRates?.locationRateGuniteFuel?.compressorRedCostPerGallon : globalRates?.rateGuniteFuel?.compressorRedCostPerGallon) || 2.50,
      mpg: 0,
      yards: 0,
      gallonsPerYard: (locationRates?.locationRateGuniteFuel?.compressorRedGallonsPerYard ? locationRates?.locationRateGuniteFuel?.compressorRedGallonsPerYard : globalRates?.rateGuniteFuel?.compressorRedGallonsPerYard) || 1.2
    },
  ]

  const mixerCostGallon = (locationRates?.locationRatePlasterFuel?.mixerCostPerGallon || globalRates?.ratePlasterFuel?.mixerCostPerGallon) || 0
  const mixerMPG = (locationRates?.locationRatePlasterFuel?.mixerMilesPerGallon || globalRates?.ratePlasterFuel?.mixerMilesPerGallon) || 0
  const mixerCostMile = mixerMPG > 0 ? (mixerCostGallon / mixerMPG) : 0

  const slurryCostGallon = (locationRates?.locationRatePlasterFuel?.slurryTruckCostPerGallon || globalRates?.ratePlasterFuel?.slurryTruckCostPerGallon) || 0
  const slurryMPG = (locationRates?.locationRatePlasterFuel?.slurryTruckMilesPerGallon || globalRates?.ratePlasterFuel?.slurryTruckMilesPerGallon) || 0
  const slurryCostMile = slurryMPG > 0 ? (slurryCostGallon / slurryMPG) : 0

  const DEFAULT_FUEL_PLASTER = [
    {
      name: 'Mixer',
      trips: 1,
      milesToJob: 0,
      costMile: mixerCostMile,
      costGallon: mixerCostGallon,
      mpg: mixerMPG,
      yards: 0,
      gallonsPerYard: 0
    },
    {
      name: 'Slurry Truck',
      trips: 1,
      milesToJob: 0,
      costMile: slurryCostMile,
      costGallon: slurryCostGallon,
      mpg: slurryMPG,
      yards: 0,
      gallonsPerYard: 0
    },
  ]

  const DEFAULT_OPTIONS_GUNITE = {
    'Cubic yards': (locationRates?.locationRateInvoice?.pricePerCubicYards ? locationRates?.locationRateInvoice?.pricePerCubicYards : globalRates?.rateInvoice?.pricePerCubicYards) || 200,
    'ASR Mix Cubic Yards': 280,
    'Perimeter/Ft': 200,
    'Spa': 600,
    'Peg board': 35,
    'Waterproofing': 75,
    'Extras': 0,
    'Minimum': 0
  }

  const DEFAULT_OPTIONS_PLASTER = {
    'Plaster': 0,
    'Spa': 600,
    'Slurry and Acid': 650,
    'Clean and Prep': 400,
    'Extras': 0,
    'Minimum': 0,
  }

  useEffect(() => {
    refreshRate()
  }, [selectedScheduler])

  return (
    <JobDetailsModalContext.Provider value={{
      handleCloseJobDetailsModal,
      isAnimatingOut, setIsAnimatingOut,
      selectedJobDetailsTab, setSelectedJobDetailsTab,
      nestedModalOpen, setNestedModalOpen,
      locations, getLocationsAsync,
      showJobDetailsModal, setShowJobDetailsModal,
      showMiniCalendar, setShowMiniCalendar,
      togglePinNote, convertRoleToSentenceCase,
      getTimeElapsedForNotes, sentenceCaseResult,
      saveUpdatedJobNote,
      createJobNote, deleteJobNote, toggleShowTooltipMenu,
      uploadedFile, toggleShowDeleteConfirmation,
      hiddenFileInput, toggleShowEditNoteInput,
      savePlanToState,
      handleSaveDetailsTab, handleCancelDetailsTab,
      detailsTabEditing, setDetailsTabEditing,
      previousMonth, nextMonth, days, today,
      setCurrentDay, isFlash, setIsFlash,
      selectedCalendarJobId, setSelectedCalendarJobId,
      showCostBreakdownWarning, setShowCostBreakdownWarning,
      isCostBreakdownChange,
      showResetWarning, setShowResetWarning,
      detailsTabErrorMessages, setDetailsTabErrorMessages,
      globalRates, locationRates,
      DEFAULT_BREAKDOWN, DEFAULT_INVOICE_ITEM, DEFAULT_CREW, DEFAULT_DRIVER,
      DEFAULT_MATERIAL_PLASTER, DEFAULT_MATERIAL_GUNITE, DEFAULT_FUEL_PLASTER, DEFAULT_FUEL_GUNITE,
      DEFAULT_OPTIONS_GUNITE, DEFAULT_OPTIONS_PLASTER,
      updatedJob, setUpdatedJob,
      updatedPool, setUpdatedPool,
      updatedCustomer, setUpdatedCustomer
    }}>
      {props.children}
    </JobDetailsModalContext.Provider>
  )
}
const useJobDetailsModal = () => useContext(JobDetailsModalContext)
export { useJobDetailsModal }
export default JobDetailsModalContextProvider
