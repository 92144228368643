import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResponsiveProvider from './contexts/responsive'
import BigSpinnerIconProvider from './contexts/bigSpinnerIcon';
import Modal from "./components/Modal";
import ModalProvider from './contexts/modal.js';
import IntakeFormProvider from './contexts/intakeForm.js';
import AuthProvider from './contexts/auth.js';
import IntakeLayout from "./views/IntakeForm/IntakeLayout/index.js";
import ProgressProvider from './contexts/progress.js';
import SchedulerLayout from "./views/Scheduler/SchedulerLayout/index.js";
import AdminLayout from './views/Admin/AdminLayout';
import CustomerLayout from './views/Customer/CustomerLayout';
import DriverLayout from './views/Driver/DriverLayout';
import CalendarSignIn from './views/CalendarSignIn';
import ForemanLayout from './views/Foreman/ForemanLayout';
import BranchAdminLayout from './views/BranchAdmin/BranchAdminLayout';
import Landing from './components/Landing/index.js'
import TopBar from './components/TopBar'
import Footer from './components/Footer'
import AdminProvider from './contexts/admin.js';
import ForemanSignIn from './views/Foreman/ForemanSignIn';
import DriverSignIn from './views/Driver/DriverSignIn';

function App() {
  return (
    <BigSpinnerIconProvider>
      <AuthProvider>
        <ResponsiveProvider>
          <ModalProvider>
            <IntakeFormProvider>
              <Modal />
              <BrowserRouter>
                <AdminProvider>
                  <ProgressProvider>
                    <Routes>
                      <Route exact={true} path='/intake-form/*' element={<IntakeLayout />} />
                      <Route exact={true} path='/customer/*' element={<CustomerLayout />} />
                      <Route exact={true} path='/driver/*' element={<DriverLayout />} />
                      <Route exact={true} path='/login' element={<CalendarSignIn />} />
                      <Route exact={true} path='/login-foreman' element={<ForemanSignIn />} />
                      <Route exact={true} path='/login-driver' element={<DriverSignIn />} />
                      <Route exact={true} path='/scheduler/*' element={<SchedulerLayout />} />
                      <Route exact={true} path='/foreman/*' element={<ForemanLayout />} />
                      <Route exact={true} path='/admin/*' element={<AdminLayout />} />
                      <Route exact={true} path='/branchadmin/*' element={<BranchAdminLayout />} />
                      <Route exact={true} path="/" element={(
                        <>
                          <TopBar />
                          <Landing />
                          <Footer />
                        </>
                      )} />
                    </Routes>
                  </ProgressProvider>
                </AdminProvider>
              </BrowserRouter>
            </IntakeFormProvider>
          </ModalProvider>
        </ResponsiveProvider>
      </AuthProvider>
    </BigSpinnerIconProvider>
  );
}

export default App;
