import React from 'react'
import { useLongPress } from '@uidotdev/usehooks'
import service from '../../service'
import { useScheduler } from '../../contexts/scheduler'

//This component (which does not have a parallel desktop calendar component) is necessary to allow the updateCrewVisibility function to know how to refer to crew.id and crew.isCalendarActive.  In the parent component, the function call is outside of the scope of where "crew" is defined so instead it is placed here where "crew" is a prop available to the whole component.
const CrewMobileButton = ({ crew }) => {
  const { selectedScheduler, setSelectedScheduler } = useScheduler()

  const updateCrewVisibility = async (crewId, oldStatus) => {
    const crewUpdateObj = {
      id: crewId,
      newStatus: !oldStatus
    }
    const { updatedCrew } = await service.updateCrewVisibility(crewUpdateObj)
    const updatedCrewIndex = selectedScheduler.location.crews.findIndex(crew => crew.id === updatedCrew.id)
    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        crews: [
          ...selectedScheduler.location.crews.slice(0, updatedCrewIndex),
          {
            ...selectedScheduler.location.crews[updatedCrewIndex],
            isCalendarActive: updatedCrew.isCalendarActive
          },
          ...selectedScheduler.location.crews.slice(updatedCrewIndex + 1)
        ]
      }
    })
  }

  const attrs = useLongPress(
    () => {
      updateCrewVisibility(crew.id, crew.isCalendarActive)
    },
    {
      threshold: 500,
    }
  );

  return (
    <div
      className={`flex justify-center items-center h-10 w-10 rounded-full -ml-[11px] font-bold pt-[1px] select-none border border-4 border-[#f3f5f9] ${crew.isCalendarActive ? `${crew.color.split(' ')[0]}` : 'bg-gray-300 text-gray-700'}`}
      title={crew.name}
      {...attrs}
    >
      {crew.name.charAt(0)}
    </div>
  )
}

export default CrewMobileButton