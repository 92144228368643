import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { useAuth } from './auth'
import { useSchedulerJobsList } from './schedulerJobsList'
import { useScheduler } from './scheduler'
import { useModal } from './modal'
import serviceMethods from '../service'
import VerifyMoveModal from "../components/VerifyMoveModal";
import { useJobDetailsModal } from "./jobDetailsModal";

export const SchedulerPlannerContext = createContext()

const SchedulerPlannerProvider = props => {
  const { me } = useAuth()
  const { setSelectedJobId, selectedScheduler, setSelectedScheduler } = useScheduler()
  const locationService = selectedScheduler?.location?.service
  const { setShowJobDetailsModal, setShowMiniCalendar, setSelectedJobDetailsTab } = useJobDetailsModal()
  const { plannerJobs } = useSchedulerJobsList()
  const { modalMethods } = useModal()


  const [isDragging, setIsDragging] = useState(false)
  const draggedFrom = useRef('')
  const draggedTo = useRef('')
  const draggedJob = useRef({})
  let spacer = document.getElementById('spacer')


  const [hideIntake, setHideIntake] = useState(false)
  const [hideBacklog, setHideBacklog] = useState(false)
  const [hideReady, setHideReady] = useState(false)
  const [hideScheduled, setHideScheduled] = useState(false)
  const [hideWorkCompleted, setHideWorkCompleted] = useState(false)
  const [hideCompleted, setHideCompleted] = useState(false)
  const [hideArchive, setHideArchive] = useState(false)

  const jobListIntake = useRef([])
  const jobListBacklog = useRef([])
  const jobListReady = useRef([])
  const jobListScheduled = useRef([])
  const jobListWorkCompleted = useRef([])
  const jobListCompleted = useRef([])
  const jobListArchive = useRef([])

  const [toggle, setToggle] = useState(true)

  const columnDetails = [
    {
      title: 'Intake',
      icon: '/PlannerColumn/intake_icon.svg',
      id: 'intake',
      setter: setHideIntake,
      hide: hideIntake,
      jobList: jobListIntake,
    },
    {
      title: 'Backlog',
      icon: '/PlannerColumn/backlog_icon.svg',
      id: 'backlog',
      setter: setHideBacklog,
      hide: hideBacklog,
      jobList: jobListBacklog,
    },
    {
      title: 'Ready for calendar',
      icon: '/PlannerColumn/ready_for_calendar_icon.svg',
      id: 'ready',
      setter: setHideReady,
      hide: hideReady,
      jobList: jobListReady,
    },
    {
      title: 'Scheduled',
      icon: '/PlannerColumn/scheduled_icon.svg',
      id: 'scheduled',
      setter: setHideScheduled,
      hide: hideScheduled,
      jobList: jobListScheduled,
    },
    {
      title: locationService === 'GUNITE' ? 'Gunite complete' : 'Plaster complete',
      icon: '/PlannerColumn/dollar_sign_icon.svg',
      id: 'workCompleted',
      setter: setHideWorkCompleted,
      hide: hideWorkCompleted,
      jobList: jobListWorkCompleted,
    },
    {
      title: 'Completed',
      icon: '/PlannerColumn/completed_icon.svg',
      id: 'completed',
      setter: setHideCompleted,
      hide: hideCompleted,
      jobList: jobListCompleted,
    },
    {
      title: 'Archive',
      icon: '/PlannerColumn/archive_icon.svg',
      id: 'archive',
      setter: setHideArchive,
      hide: hideArchive,
      jobList: jobListArchive,
    },
  ]

  //DRAG AND DROP FUNCTIONS

  function handleDragStart(e) {
    e.dataTransfer.effectAllowed = 'move'
    setIsDragging(true)

    draggedFrom.current = e.target.parentNode.dataset.name
    draggedJob.current = JSON.parse(e.target.dataset.job)

    // styles applied to the job card that stays in the original position
    this.setAttribute('id', 'dragging')
  }

  function handleDragOver(e) {
    e.preventDefault()

    if (!spacer) {
      spacer = document.getElementById('spacer')
    }
    const elementBelowCursor = getElementBelowCursor(this, e.clientY)

    // appending spacer to column
    if (elementBelowCursor === null) {
      this.appendChild(spacer)
    } else {
      this.insertBefore(spacer, elementBelowCursor.element)
    }
    // hide spacer if next to starting location
    const next = spacer.nextSibling?.id
    const previous = spacer.previousSibling?.id
    if (next === 'dragging' || previous === 'dragging') {
      spacer?.classList.add('hideSpacer')
    } else {
      // make spacer visible if not next to starting location
      spacer?.classList.remove('hideSpacer')
    }
  }

  // determine which element is below the cursor
  const getElementBelowCursor = (droppable, y) => {
    const draggableElements = [...droppable.querySelectorAll('.draggable')]
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect()
      const offset = y - box.top - box.height / 2
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child }
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY })
  }

  function handleDrop(e) {
    e.preventDefault()
    const droppable = e.target.closest('.droppable')
    draggedTo.current = droppable.dataset.name
  }

  async function handleDragEnd(e) {
    this.setAttribute('id', '')
    setIsDragging(false)

    // hide the spacer
    const spacer = document.getElementById('spacer')
    spacer.classList.add('hideSpacer')

    await postDragHandling()
    clearDragRefs()
  }

  // update dates based on which column a jobCard is dropped in/coming from
  const postDragHandling = async () => {
    const allJobLogsPresent = checkDailyJobLogs(draggedJob.current)
    const costBreakdownPresent = draggedJob.current.costBreakdown.length > 0 ? draggedJob.current.costBreakdown[0].submittedAt !== null : false
    const estimate = draggedJob.current.jobType === 'plaster' ? draggedJob.current.estimatedIA : draggedJob.current.estimatedYards

    // dropped on same place it came from
    if (draggedFrom.current === draggedTo.current) return

    // job cards can be moved anywhere when all job logs and cost breakdown have been submitted
    // or anywhere but complete if all job logs are submitted but not costBreakdown
    if ((allJobLogsPresent && costBreakdownPresent) || (allJobLogsPresent && draggedTo.current !== 'completed')) {
      let cb = () => { }
      //if job is not currently at or past scheduled and is dragged to workCompleted, completed, or archive, open a verification modal then update timestamps.
      if (['workCompleted', 'completed', 'archive'].includes(draggedTo.current) && !draggedJob.current.scheduledAt) {
        const id = draggedJob.current.id
        cb = async () => {
          await serviceMethods.updateDate(id, 'scheduled')
        }
      }
      const target = draggedTo.current
      const jobId = draggedJob.current.id
      const title = `Move ${draggedJob.current.jobName} to ${draggedTo.current}`
      const message = ''
      const subMessage = 'Are you sure?'
      const oneButton = false
      openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton, cb)

      return
    }

    // check the estimate value if trying to move to 'ready' or later
    if ((draggedFrom.current === 'intake' && draggedTo.current !== 'backlog') || (draggedFrom.current === 'backlog' && draggedTo.current !== 'intake')) {
      if (Number(estimate) === 0) {
        // cancels move, opens job details modal
        setSelectedJobId(draggedJob.current.id)
        setShowJobDetailsModal(true)

        const target = draggedTo.current
        const jobId = draggedJob.current.id
        const title = 'Verify job details'
        const message = `Check that all necessary information is present. Calculate estimated ${draggedJob.current.jobType === 'plaster' ? 'IA' : 'yards'}.`
        const subMessage = ''
        const oneButton = true
        openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

        if (draggedJob.current.openedAt === null) {
          serviceMethods.updateDate(draggedJob.current.id, 'openedAt')
        }
        return
      }

      // deletes calendarJobs without job logs if job card moved backward from scheduled
    } else if (draggedFrom.current === 'scheduled') {
      const calendarJobIds = []
      draggedJob.current.calendarJobs.forEach((calJob) => {
        // only grabbing calJob ids of calJobs without a dailyJobLog
        if (!calJob.jobLog) {
          calendarJobIds.push(calJob.id)
        }
      })
      if (draggedTo.current === 'backlog'
        || draggedTo.current === 'intake'
        || draggedTo.current === 'ready') {
        const target = draggedTo.current
        const jobId = draggedJob.current.id
        const title = `Move ${draggedJob.current.jobName} to ${draggedTo.current}`
        const message = `This will delete all scheduled shifts for ${draggedJob.current.jobName}.`
        const subMessage = 'Are you sure?'
        const oneButton = false
        const cb = async () => {
          const { updatedJob } = await serviceMethods.markCalendarJobDeletedFromPlanner({ ids: calendarJobIds, jobId: jobId, createActivityLog: false })
          const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

          setSelectedScheduler({
            ...selectedScheduler,
            location: {
              ...selectedScheduler.location,
              jobs: [
                ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
                updatedJob,
                ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
              ]
            }
          })
        }
        openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton, cb)

        return
      }

      // Block moves from workCompleted to completed when costBreakdown hasn't been submitted
    } else if (draggedFrom.current === 'workCompleted') {
      const target = draggedTo.current
      const jobId = draggedJob.current.id
      let title = `Move ${draggedJob.current.jobName} to ${draggedTo.current}`
      let message = draggedFrom.current === 'workCompleted' ? 'Daily job log has already been submitted.' : 'Cost breakdown has already been submitted'
      let subMessage = 'Are you sure?'
      let oneButton = false

      if (draggedTo.current === 'completed') {
        title = 'Move blocked'
        message = 'Complete and submit the cost breakdown.'
        subMessage = ''
        oneButton = true
        setSelectedJobId(draggedJob.current.id)
        setShowJobDetailsModal(true)
        setSelectedJobDetailsTab('Cost Breakdown')
      }

      openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

      return

      // verify if user is sure they want to archive
    } else if (draggedFrom.current === 'archive') {
      const target = draggedTo.current
      const jobId = draggedJob.current.id
      const title = `Move ${draggedJob.current.jobName} to ${draggedTo.current}`
      const message = ''
      const subMessage = 'Are you sure?'
      const oneButton = false
      openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

      return
    }

    // Block move to scheduled and open mini scheduler so user can schedule a calJob
    if (draggedTo.current === 'scheduled' && draggedJob.current.scheduledAt === null) {
      // cancel move + open mini calendar
      setSelectedJobId(draggedJob.current.id)
      setShowJobDetailsModal(true)
      setShowMiniCalendar(true)
      return
    } else if (draggedTo.current === 'backlog' || draggedTo.current === 'archive' || draggedTo.current === 'intake') {
      const target = draggedTo.current
      const jobId = draggedJob.current.id
      const title = `Move ${draggedJob.current.jobName} to ${draggedTo.current}`
      const message = draggedTo.current === 'intake' ? `This will reset the job.` : ''
      const subMessage = 'Are you sure?'
      const oneButton = false
      openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

      return

      // Block move to workCompleted if job hasn't been scheduled
    } else if (draggedTo.current === 'workCompleted') {
      // when trying to skip the scheduled column
      if (draggedJob.current.scheduledAt === null && !allJobLogsPresent) {
        const target = draggedTo.current
        const jobId = draggedJob.current.id
        const title = 'Move blocked'
        const message = 'Job needs to be scheduled first.'
        const subMessage = ''
        const oneButton = true
        openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

        return

        // Block move to workCompleted if there are missing job logs
      } else if (draggedJob.current.workCompletedAt === null && !allJobLogsPresent) {
        const multipleCalJobs = draggedJob.current.calendarJobs.length > 1
        const target = draggedTo.current
        const jobId = draggedJob.current.id
        const title = 'Move blocked'
        const message = multipleCalJobs ? 'Daily Job Logs need to be submitted.' : 'Daily Job Log needs to be submitted.'
        const subMessage = ''
        const oneButton = true
        openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

        return
      }

      // Block move when trying to skip the 'work complete' column
    } else if (draggedTo.current === 'completed') {

      if (draggedJob.current.costBreakdownSubmittedAt === null && !allJobLogsPresent) {
        const target = draggedTo.current
        const jobId = draggedJob.current.id
        const title = 'Move blocked'
        const message = 'The job must be Scheduled with Daily Job Log/s and Cost Breakdown submitted in order to be completed.'
        const subMessage = ''
        const oneButton = true
        openVerifyMoveModal(target, jobId, title, message, subMessage, oneButton)

        return
      }
    }

    await updateJobStatus()
  }

  const updateJobStatus = async (dateToUpdate = draggedTo.current, id = draggedJob.current.id) => {
    const jobDetails = {
      //used for activity log and change job status
      id,
      activityType: dateToUpdate,

      //used for activity log.  A USER id
      performedById: me.id,
    }

    const { updatedJob } = await serviceMethods.updateJobStatus(jobDetails)
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          updatedJob,
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
  }

  const openVerifyMoveModal = (target, jobId, title, message, subMessage, oneButton = false, cb = () => { }) => {
    modalMethods.setContent(
      <VerifyMoveModal
        updateJobStatus={updateJobStatus}
        destination={target}
        jobId={jobId}
        title={title}
        message={message}
        subMessage={subMessage}
        oneButton={oneButton}
        cb={cb}
      />
    )
    modalMethods.open()
  }

  const checkDailyJobLogs = (job) => {
    let allPresent = true
    if (job.calendarJobs.length === 0) allPresent = false
    for (let i = 0; i < job.calendarJobs.length; i++) {
      if (job.calendarJobs[i].jobLog === null || job.calendarJobs[i].jobLog.submittedAt === null) {
        allPresent = false
      }
    }
    return allPresent
  }

  const clearDragRefs = () => {
    draggedFrom.current = ''
    draggedTo.current = ''
    draggedJob.current = {}
  }

  // SORTING FUNCTIONS

  const organizeJobsByColumn = () => {
    jobListIntake.current = []
    jobListBacklog.current = []
    jobListReady.current = []
    jobListScheduled.current = []
    jobListWorkCompleted.current = []
    jobListCompleted.current = []
    jobListArchive.current = []

    const allJobs = plannerJobs
    if (allJobs.length === 0) {
      setToggle(!toggle)
      return
    }

    allJobs.forEach((job) => {
      if (job.archivedAt) {
        jobListArchive.current.push(job)
      } else if (job.completedAt) {
        jobListCompleted.current.push(job)
      } else if (job.workCompletedAt) {
        jobListWorkCompleted.current.push(job)
      } else if (job.scheduledAt) {
        jobListScheduled.current.push(job)
      } else if (job.readyAt) {
        jobListReady.current.push(job)
      } else if (job.backloggedAt) {
        jobListBacklog.current.push(job)
      } else if (job.submittedAt) {
        jobListIntake.current.push(job)
      }
    })

    setToggle(!toggle)
  }

  useEffect(() => {
    organizeJobsByColumn()
  }, [plannerJobs])

  useEffect(() => {
    const droppables = document.querySelectorAll('.droppable')

    droppables.forEach(droppable => {
      droppable.addEventListener('dragover', handleDragOver)
      droppable.addEventListener('drop', handleDrop)
    })

    return () => {
      droppables.forEach(droppable => {
        droppable.removeEventListener('dragover', handleDragOver)
        droppable.removeEventListener('drop', handleDrop)
      })
    }
  }, [])

  return (
    <SchedulerPlannerContext.Provider value={{
      isDragging, toggle,
      handleDragStart, handleDragEnd,
      columnDetails, updateJobStatus,
    }}>
      {props.children}
    </SchedulerPlannerContext.Provider>
  )
}

const useSchedulerPlanner = () => useContext(SchedulerPlannerContext)
export { useSchedulerPlanner }
export default SchedulerPlannerProvider
