import React from 'react'
import service from "../../service"
import { useModal } from '../../contexts/modal'

const CustomersNewJobModal = ({ customer, selectedScheduler, setSelectedScheduler, listenForMe }) => {
  const { modalMethods } = useModal()


  const handleCreateNewJob = async () => {
    // service method to create a new job goes here
    const jobDetails = {
      ownerId: customer.user.id,
      locationId: selectedScheduler.location.id,
      isPlaster: selectedScheduler.location.service === 'PLASTER'
    }
    const { newJob } = await service.createBlankJob(jobDetails)

    // tells customerList to act the next time me changes
    listenForMe.current = { listen: true, id: newJob.id }
    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs,
          newJob
        ]
      }
    })
    modalMethods.close()
  }

  return (
    <div className='h-fit w-full sm:w-fit py-8 px-3 sm:px-32 flex flex-col items-center justify-between rounded-2xl border-2 border-navy-light text-dark-blue bg-all-f-white'>
      <div className='flex flex-col w-full justify-center items-center'>
        <p className='text-2xl pb-4'>Verify Customer Information</p>
        <div className='flex w-full gap-2 whitespace-nowrap overflow-hidden'>
          <p className='w-3/12 flex justify-end'>Business:</p>
          <p className='w-9/12 flex'>{customer.bizName}</p>
        </div>
        <div className='flex w-full gap-2 whitespace-nowrap overflow-hidden'>
          <p className='w-3/12 flex justify-end'>Sales Rep:</p>
          <p className='w-9/12 flex'>{`${customer.firstName} ${customer.lastName}`}</p>
        </div>
        <div className='flex w-full gap-2 whitespace-nowrap overflow-hidden'>
          <p className='w-3/12 flex justify-end'>Phone:</p>
          <p className='w-9/12 flex'>{customer.phone}</p>
        </div>
        <div className='flex w-full gap-2 whitespace-nowrap overflow-hidden'>
          <p className='w-3/12 flex justify-end'>Email:</p>
          <p className='w-9/12 flex'>{customer.user.email}</p>
        </div>
      </div>

      <div className='flex flex-col w-full p-4'>
        <p className='w-full flex justify-center'>{customer.bizAddress}</p>

        <p className='w-full flex justify-center'>{`${customer.city}, ${customer.state}`}</p>

        <p className='w-full flex justify-center'>{customer.zipCode}</p>
      </div>

      <div className='flex flex-col w-full justify-center items-center'>
        <p className='text-2xl'>Verify Location</p>

        <div className='flex flex-col w-full p-4'>

          <p className='w-full flex justify-center font-semibold'>{selectedScheduler.location.label}</p>

          <p className='w-full flex justify-center'>{selectedScheduler.location.address}</p>

          <p className='w-full flex justify-center'>{`${selectedScheduler.location.city}, ${selectedScheduler.location.state}`}</p>

          <p className='w-full flex justify-center'>{selectedScheduler.location.zipCode}</p>


        </div>

      </div>

      <div className='flex items-center gap-4'>
        <button
          className='border-2 rounded-lg px-2 py-1'
          onClick={() => {
            modalMethods.close()
          }}
        >Cancel</button>

        <button
          className='border-2 rounded-md px-2 py-1'
          onClick={() => {
            handleCreateNewJob()
          }}
        >Create</button>
      </div>
    </div>
  )
}

export default CustomersNewJobModal;