import React, { useCallback, createContext, useContext, useState, useEffect } from 'react'
import { useBigSpinnerIcon } from './bigSpinnerIcon'
import { useScheduler } from './scheduler'
import { useAdmin } from './admin'
import { useAuth } from './auth'
import serviceMethods from '../service'
import { useLocation } from 'react-router-dom'

const AdminFiles = createContext()

const AdminFilesProvider = props => {
  const RESULTS_PER_PAGE = 10;
  const UPLOADED_FILE_DEFAULT = {name: ''}
  const [selectedFileUris, setSelectedFileUris] = useState([]);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false)
  const [displayItems, setDisplayItems] = useState([]);
  const [allItems, setAllItems] = useState([])
  const [allLocationFiles, setAllLocationFiles] = useState([])
  const { selectedScheduler } = useScheduler();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("fileUri");
  const [sortAscending, setSortAscending]= useState(false);
  const { me, getProfile } = useAuth() 
  const {getLocationIdFromUrl, selectedLocation, setSelectedLocation} = useAdmin()
  const [uploadedFile, setUploadedFile] = useState(UPLOADED_FILE_DEFAULT)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const { pathname } = useLocation();
  const { setIsBigSpinnerShowing } = useBigSpinnerIcon()


  const confirmDeleteHeader = selectedFileUris.length > 1 ? "Delete files" : "Delete file"
  const confirmDeleteDescription = selectedFileUris.length > 1 ? `Are you sure you want to delete these files?` : `Are you sure you want to delete this file?`

  const onCancelFileUpload  = () => {
    setUploadModalOpen(false)
    setUploadedFile(UPLOADED_FILE_DEFAULT)
  }

  const onConfirmFileUpload = async () => {
    if (!uploadedFile.name) { return }
    if (!uploadedFile.path) { return }

    const formData = new FormData()
    formData.append('file', uploadedFile)
    formData.append("creatorId", me.id)
    formData.append("locationId", selectedLocation.id)
    formData.append("fileName", uploadedFile.name)

    const res = await serviceMethods.uploadLocationFile(formData)
    if (res.ok) {
      const uploadedLocationFile = await res.json()
    }

    setUploadModalOpen(false)
    setUploadedFile(UPLOADED_FILE_DEFAULT)
    updateLocationFiles()
    getProfile()
    setIsBigSpinnerShowing(false)
  }
  
  const handleTrashClick = async () => {
    if (selectedFileUris.length > 0) {
      setShowConfirmDeletePopup(true)
    }
  }

  const handleConfirmDelete = async () => {
    await serviceMethods.deleteLocationFiles(selectedFileUris)
    getProfile()
    setSelectedFileUris([])
    setShowConfirmDeletePopup(false)
    setIsBigSpinnerShowing(false)
  }

  const onDrop = useCallback(acceptedFiles => {
    setUploadedFile(acceptedFiles[0])
  }, [])

  const updateLocationFiles = () => {
    const selectedLocationId = getLocationIdFromUrl()
    const availableLocations = me?.admin?.allLocations ? me.admin.allLocations : me.scheduler.map(scheduler => scheduler.location)
    const selectedLocation = availableLocations.find(location => location.id == selectedLocationId)
    console.log('selectedLocation', selectedLocation)
    const allLocationFiles = selectedLocation ? selectedLocation.locationFiles : []
    setAllLocationFiles(allLocationFiles)
    if (selectedLocation){
      setSelectedLocation(selectedLocation)
    }
  }

  useEffect(() => {
    if (!me) { return }
    if (!pathname.includes('/details')){
      updateLocationFiles()
    }
  }, [me, selectedLocation]);

  
  const paginate = (currentPage, items) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    return items.slice(startIndex, Math.min(endIndex, items.length));
  }

  const handleColumnHeaderClick = (column) => {
    setSortBy(column);
    if (sortBy === column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  }

  const sort = (items) => {
    if (sortBy === 'fileUri') {
      items.sort(function(a,b) {
        if(a.fileUri.toLowerCase() > b.fileUri.toLowerCase()) {return  1;}
        if(a.fileUri.toLowerCase() < b.fileUri.toLowerCase()) {return -1;}
        if(b.createdAt > a.createdAt) {return  1;}
        if(b.createdAt < a.createdAt) {return -1;}
        return 0;
      });
    } else if (sortBy === 'createdAt') {
      items.sort(function(a,b) {
        if(b.createdAt > a.createdAt) {return  1;}
        if(b.createdAt < a.createdAt) {return -1;}
        if(a.fileUri.toLowerCase() > b.fileUri.toLowerCase()) {return  1;}
        if(a.fileUri.toLowerCase() < b.fileUri.toLowerCase()) {return -1;}
        return 0;
      });
    }

    if (!sortAscending) {
      items.reverse();
    }
    return items
  }

  useEffect(() => {
    const sortedItems = sort(allLocationFiles? allLocationFiles : [])
    const searchFilteredItems = sortedItems.filter(item => {
      return item.fileUri.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setAllItems(searchFilteredItems)

    const paginatedItems = paginate(currentPage, searchFilteredItems)
    setDisplayItems(paginatedItems)
  }, [selectedScheduler, searchTerm, currentPage, sortBy, sortAscending, allLocationFiles])

  useEffect(() => {
    if (allLocationFiles?.length == 0) { return }
    const paginatedJobs = paginate(currentPage, allItems); 
    setDisplayItems(paginatedJobs);
  }, [currentPage, allItems])

  useEffect(() => {
    if (allLocationFiles?.length == 0) { return }

    if (allItems.length > 0 && currentPage > Math.ceil(allItems.length / RESULTS_PER_PAGE)) {
        setCurrentPage(Math.ceil(allItems.length / RESULTS_PER_PAGE));  // Adjust page to the last possible page with items
    } else if (allItems.length === 0) {
        setCurrentPage(1);  // Reset to first page if no items exist
    }
  }, [allItems]);  // Adjust currentPage based on items count

  return (
    <AdminFiles.Provider value={{
      selectedFileUris,
      setSelectedFileUris,
      showConfirmDeletePopup,
      setShowConfirmDeletePopup,
      confirmDeleteHeader,
      confirmDeleteDescription,
      handleTrashClick,
      handleConfirmDelete,
      searchTerm,
      setSearchTerm,
      currentPage,
      setCurrentPage,
      RESULTS_PER_PAGE,
      sortBy,
      setSortBy,
      sortAscending,
      setSortAscending,
      handleColumnHeaderClick,
      allItems,
      displayItems,
      updateLocationFiles,
      onCancelFileUpload,
      onConfirmFileUpload,
      onDrop,
      uploadedFile,
      setUploadedFile,
      uploadModalOpen,
      setUploadModalOpen,
      handleTrashClick,
      sort,
      paginate
    }}>
      {props.children}
    </AdminFiles.Provider>
  )
}

const useAdminFiles = () => useContext(AdminFiles)

export { useAdminFiles }
export default AdminFilesProvider
