import React, { useState, useRef, useEffect } from 'react'
import { useModal } from '../../contexts/modal'
import { useSchedulerJobLog } from '../../contexts/schedulerJobLog'
import { useAuth } from '../../contexts/auth'
import SelectPersonnel from '../../components/SelectPersonnel'
import service from '../../service'
import SchedulerJobLogDateSelector from '../SchedulerJobLogDateSelector'
import JobSelector from '../JobSelector'
import ForemanSelector from '../ForemanSelector'
import DailyJobLogPhoto from '../DailyJobLogPhoto'
import AskIfContinueModal from '../AskIfContinueModal'
import AskIfContinueDateModal from '../AskIfContinueDateModal'
import { useScheduler } from '../../contexts/scheduler'
import SchedulerLocationDropdown from "../../components/SchedulerLocationDropdown";
import { useResponsive } from "../../contexts/responsive";
import cookies from 'browser-cookies';
import JobLogPDFViewer from '../JobLogPDFViewer'
import UploadToOneDriveButton from "../UploadToOneDriveButton";
import JobLogPDFTemplate from '../JobLogPDFTemplate';

const SchedulerDailyJobLog = () => {
  const { me } = useAuth()
  const { language, setSelectedDay, selectedCalendarJob, setSelectedCalendarJob, selectedCrewMembers, setSelectedCrewMembers,
    locationCrewMembers, locationDrivers, selectedDrivers, setSelectedDrivers, todaysCalendarJobs, selectedForeman, setSelectedForeman,
    isEditing, setIsEditing, allPersonnel, personnelLocation, updateLocationPersonnel,
    deletedLocationCrewMembers, setDeletedLocationCrewMembers, deletedLocationDrivers, setDeletedLocationDrivers,
    noAvailableForemen, setNoAvailableForemen
  } = useSchedulerJobLog()
  const { modalMethods } = useModal()
  const [startTime, setStartTime] = useState(null) //Tied to the 'value' field of the time inputs.  Looks like '07:00', '13:00', etc.
  const [endTime, setEndTime] = useState(null) //Tied to the 'value' field of the time inputs.  Looks like '07:00', '13:00', etc.
  const [yards, setYards] = useState(null)
  const [spa, setSpa] = useState(null)
  const [steps, setSteps] = useState(null)
  const [benches, setBenches] = useState(null)
  const [wallFittings, setWallFittings] = useState(null)
  const [mainDrains, setMainDrains] = useState(null)
  const [guniteComments, setGuniteComments] = useState(null)
  const [plasterComments, setPlasterComments] = useState(null)
  const [batches, setBatches] = useState(null)
  const crewMemberOrDriver = useRef(null)
  const hiddenFileInput = useRef(null)
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isChangingForeman, setIsChangingForeman] = useState(false)
  const [pdfDisabled, setPDFDisabled] = useState(true)
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions } = useScheduler()
  const { isMobileView } = useResponsive();

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedCalendarJob?.jobId)

  const createNewJobLog = async (e) => {
    e.preventDefault()
    const jobDetails = {
      calendarJobId: selectedCalendarJob.id,
      userId: selectedCalendarJob.crews[0].crew.foreman.user.id, //the id of the foreman associated with the job
      schedulerId: me.id
    }
    const { updatedCalendarJob } = await service.createNewJobLogScheduler(jobDetails)
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedCalendarJob.jobId)
    const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedCalendarJob.id)
    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          {
            ...selectedScheduler.location.jobs[selectedJobIndex],
            calendarJobs: [
              ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
              updatedCalendarJob,
              ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
            ]
          },
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })

    // update the state specifically for filling out a blank calendar job
    setSelectedCrewMembers([])
    setSelectedDrivers([])
    setSelectedForeman(updatedCalendarJob.jobLog.foreman)
    setYards(null)
    setBatches(null)
    setStartTime(null)
    setEndTime(null)
    setIsEditing(true)
    setSelectedCalendarJob(updatedCalendarJob)
    setSpa(null)
    setSteps(null)
    setBenches(null)
    setWallFittings(null)
    setMainDrains(null)
    setGuniteComments(null)
    setPlasterComments(null)
  }

  const handleUpload = async (e) => {
    const filesUploaded = e.target.files
    const formData = new FormData()
    if (filesUploaded) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/svg', 'image/gif', 'image/jpg', 'image/heic', 'application/pdf'];
      for (const file of filesUploaded) {
        if (allowedTypes.includes(file.type)) {
          formData.append('attachment', file)
        }
      }
    }
    formData.append('jobId', selectedCalendarJob.job.id)
    formData.append('userId', me.id) //used as creatorId for JobFile.  Note: this is the id in the USER table, not the SCHEDULER table.  And the associated foreman of the jobLog is set by the submit button at the bottom of the page, not this photo upload method
    formData.append('jobLogId', selectedCalendarJob.jobLog.id)
    formData.append('calendarJobId', selectedCalendarJob.id)
    const res = await service.uploadPhotosToJobLog(formData)
    setSelectedCalendarJob(res.updatedCalendarJob)
  }

  const deletePhoto = async (id) => {
    const photoDetails = { photoId: id, calendarJobId: selectedCalendarJob.id }
    const res = await service.markPoWPhotoDeleted(photoDetails)
    setSelectedCalendarJob(res.updatedCalendarJob)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const scheduledDate = new Date(selectedCalendarJob.scheduledDate) //date object
    const startTimeString = scheduledDate.setUTCHours(parseInt(startTime)) //string
    const startTimeUTC = new Date(startTimeString).toUTCString() //string
    const endTimeString = scheduledDate.setUTCHours(parseInt(endTime)) //string
    const endTimeUTC = new Date(endTimeString).toUTCString() //string

    const formDetails = {
      userId: me.id,
      jobId: selectedCalendarJob.jobId,
      jobLogId: selectedCalendarJob.jobLog.id,
      scheduledDate: selectedCalendarJob.scheduledDate,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      yards: yards,
      batches: batches,
      crewMembers: selectedCrewMembers,
      drivers: selectedDrivers,
      foreman: selectedForeman,
      calendarJobId: selectedCalendarJob.id,
      spa: spa,
      steps: steps,
      benches: benches,
      wallFittings: wallFittings,
      mainDrains: mainDrains,
      guniteComments: guniteComments,
      plasterComments: plasterComments
    }
    const { updatedCalendarJob } = await service.submitJobLogScheduler(formDetails)

    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedCalendarJob.jobId)
    const selectedCalendarJobIndex = selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.findIndex(calendarJob => calendarJob.id === updatedCalendarJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          {
            ...selectedScheduler.location.jobs[selectedJobIndex],
            calendarJobs: [
              ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(0, selectedCalendarJobIndex),
              updatedCalendarJob,
              ...selectedScheduler.location.jobs[selectedJobIndex].calendarJobs.slice(selectedCalendarJobIndex + 1),
            ]
          },
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })

    setSelectedCalendarJob(updatedCalendarJob)
    setIsEditing(false)
  }

  const askIfContinue = (calendarJobId, previousCalendarJob) => {
    if (isEditing) {
      modalMethods.setContent(
        <AskIfContinueModal
          chooseCalendarJob={chooseCalendarJob}
          calendarJobId={calendarJobId}
          previousCalendarJob={previousCalendarJob}
          setIsEditing={setIsEditing}
        />
      )
      modalMethods.open()
    } else {
      chooseCalendarJob(calendarJobId)
    }
  }

  const askIfContinueDate = (dateInfo) => {
    if (isEditing) {
      modalMethods.setContent(
        <AskIfContinueDateModal
          setIsEditing={setIsEditing}
          dateInfo={dateInfo}
          setSelectedDay={setSelectedDay}
        />
      )
      modalMethods.open()
    } else {
      const date = new Date(dateInfo.year, dateInfo.month, dateInfo.day)
      setSelectedDay(date)
    }
  }

  const chooseCalendarJob = (calendarJobId) => {
    const newSelectedCalendarJob = todaysCalendarJobs.find(calendarJob => calendarJob.id === parseInt(calendarJobId))
    setSelectedCalendarJob(newSelectedCalendarJob)
    if (newSelectedCalendarJob?.jobLog?.id) {
      const newSelectedCrewMembers = newSelectedCalendarJob.jobLog.crewMembers.map(object => object.crewMember)
      setSelectedCrewMembers(newSelectedCrewMembers)
      const newSelectedDrivers = newSelectedCalendarJob.jobLog.drivers.map(object => object.driver)
      setSelectedDrivers(newSelectedDrivers)
      //Determine the foreman from the data belonging to the joblog.  If the foreman for the jobLog is undefined, (i.e., when creating a new job log as the scheduler) determine the foreman initially from the crews property of the calendar job (which is who it was assigned to on the calendar)
      if (newSelectedCalendarJob.jobLog.foreman) {
        setSelectedForeman(newSelectedCalendarJob.jobLog.foreman)
      } else {
        setSelectedForeman(newSelectedCalendarJob.crews[0].crew.foreman.user)
      }
      setYards(newSelectedCalendarJob.jobLog.yards)
      setBatches(newSelectedCalendarJob.jobLog.batches)
      const timeOptions = {
        hour12: false,
      }
      setSpa(newSelectedCalendarJob.jobLog.spa)
      setSteps(newSelectedCalendarJob.jobLog.steps)
      setBenches(newSelectedCalendarJob.jobLog.benches)
      setWallFittings(newSelectedCalendarJob.jobLog.wallFittings)
      setMainDrains(newSelectedCalendarJob.jobLog.mainDrains)
      setGuniteComments(newSelectedCalendarJob.jobLog.guniteComments)
      setPlasterComments(newSelectedCalendarJob.jobLog.plasterComments)
      if (newSelectedCalendarJob.jobLog.startTime) {
        setStartTime(newSelectedCalendarJob.jobLog.startTime.substring(newSelectedCalendarJob.jobLog.startTime.length - 12, newSelectedCalendarJob.jobLog.startTime.length - 7))
      } else {
        setStartTime(null)
      }
      if (newSelectedCalendarJob.jobLog.endTime) {
        setEndTime(newSelectedCalendarJob.jobLog.endTime.substring(newSelectedCalendarJob.jobLog.endTime.length - 12, newSelectedCalendarJob.jobLog.endTime.length - 7))
      } else {
        setEndTime(null)
      }
    } else {
      setSelectedCrewMembers([])
      setSelectedDrivers([])
      setYards(null)
      setBatches(null)
      setStartTime(null)
      setEndTime(null)
      setSpa(null)
      setSteps(null)
      setBenches(null)
      setWallFittings(null)
      setMainDrains(null)
      setGuniteComments(null)
      setPlasterComments(null)
    }
  }

  const limitToNumbers = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    e.target.value = value
  }

  const yardsOrBatchesInput = () => {
    if (selectedCalendarJob.job.jobType === 'gunite') {
      return <>
        <label
          htmlFor='yards'
          className='text-sm text-gray-blue'
        >Yards</label>
        <input
          id='yards'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={yards || ''}
          onChange={(e) => {
            e.preventDefault()
            limitToNumbers(e)
            setYards(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='spa'
          className='text-sm text-gray-blue'
        >Spa</label>
        <input
          id='spa'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={spa || ''}
          onChange={(e) => {
            e.preventDefault()
            setSpa(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='steps'
          className='text-sm text-gray-blue'
        >Steps</label>
        <input
          id='steps'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={steps || ''}
          onChange={(e) => {
            e.preventDefault()
            setSteps(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='benches'
          className='text-sm text-gray-blue'
        >Benches</label>
        <input
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={benches || ''}
          onChange={(e) => {
            e.preventDefault()
            setBenches(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label htmlFor="guniteComments" className="text-sm text-gray-blue">
          Comments
        </label>
        <textarea
          id='guniteComments'
          className={`w-full border rounded resize-none ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={guniteComments || ''}
          onChange={(e) => {
            e.preventDefault();
            setGuniteComments(e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          disabled={!isEditing}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </>
    } else {
      return <>
        <label
          htmlFor='batches'
          className='text-sm text-gray-blue'
        >Batches</label>
        <input
          id='batches'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={batches || ''}
          onChange={(e) => {
            e.preventDefault()
            limitToNumbers(e)
            setBatches(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='wallFittings'
          className='text-sm text-gray-blue'
        >Wall Fittings</label>
        <input
          id='wallFittings'
          value={wallFittings || ''}
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          onChange={(e) => {
            e.preventDefault()
            setWallFittings(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='mainDrains'
          className='text-sm text-gray-blue'
        >Main Drains</label>
        <input
          id='mainDrains'
          value={mainDrains || ''}
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          onChange={(e) => {
            e.preventDefault()
            setMainDrains(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label htmlFor="plasterComments" className="text-sm text-gray-blue">
          Comments
        </label>
        <textarea
          id='plasterComments'
          className={`w-full border rounded resize-none ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={plasterComments || ''}
          onChange={(e) => {
            e.preventDefault();
            setPlasterComments(e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          disabled={!isEditing}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </>
    }
  }

  const submitOrEditButton = () => {
    if (isEditing) {
      return <button
        type='button'
        onClick={handleSubmit}
        disabled={errorChecker()}
        className={`w-full p-4 font-semibold rounded text-lg ${errorChecker() ? 'bg-black/10 text-black/40' : 'bg-dark-blue text-white'}`}>
        Submit
      </button>
    } else {
      return <button
        type="button"
        onClick={() => {
          setIsEditing(true)
        }}
        className={`w-full p-4 font-semibold rounded text-lg bg-dark-blue text-white`}>
        Click to edit
      </button>
    }
  }

  useEffect(() => {
    if (crewMemberOrDriver.current === 'crewMember') {
      modalMethods.setContent(<SelectPersonnel
        personnel={locationCrewMembers}
        jobPersonnel={selectedCrewMembers}
        setJobPersonnel={setSelectedCrewMembers}
        allPersonnel={allPersonnel}
        deletedPersonnel={deletedLocationCrewMembers}
        setDeletedPersonnel={setDeletedLocationCrewMembers}
        crewMembersOrDrivers={'crewMembers'}
        personnelLocation={personnelLocation}
        updateLocationPersonnel={updateLocationPersonnel}
      />)
    } else {
      modalMethods.setContent(<SelectPersonnel
        personnel={locationDrivers}
        jobPersonnel={selectedDrivers}
        setJobPersonnel={setSelectedDrivers}
        allPersonnel={allPersonnel}
        deletedPersonnel={deletedLocationDrivers}
        setDeletedPersonnel={setDeletedLocationDrivers}
        crewMembersOrDrivers={'drivers'}
        personnelLocation={personnelLocation}
        updateLocationPersonnel={updateLocationPersonnel}
      />)
    }
  }, [selectedCrewMembers, selectedDrivers, locationCrewMembers, locationDrivers,
    setSelectedCrewMembers, setSelectedDrivers, crewMemberOrDriver, personnelLocation,
    deletedLocationCrewMembers, deletedLocationDrivers
  ])

  const errorChecker = () => {
    if (startTime === null ||
      endTime === null ||
      selectedCrewMembers.length === 0 ||
      (yards === null && batches === null) ||
      (selectedCalendarJob.job.jobType === 'gunite' && selectedDrivers.length === 0) ||
      (selectedCalendarJob.job.jobType === 'gunite' && yards === '') ||
      (selectedCalendarJob.job.jobType === 'plaster' && batches === '')
    ) {
      return true
    } else {
      return false
    }
  }

  const viewPDF = (prop) => {
    modalMethods.setContent(<JobLogPDFViewer calendarJob={prop} selectedScheduler={selectedScheduler} selectedJob={selectedJob} />)
    modalMethods.open()
  }

  useEffect(() => {
    if (isEditing || !(selectedCalendarJob?.jobLog?.id)) {
      setPDFDisabled(true)
    } else {
      setPDFDisabled(false)
    }
  }, [isEditing, selectedCalendarJob])

  return (
    <div className='flex flex-col gap-3 w-full p-4 lg:p-0 lg:w-2/3 lg:mx-auto lg:my-4 lg:border lg:rounded-2xl lg:border-navy-light'>
      <div className='flex flex-col'>
        <div className='bg-navy-bright/30 hidden lg:inline rounded-t-2xl border-b border-navy-light p-6'>
          <div className='flex flex-row justify-between'>
            <p className='text-dark-blue text-xl font-semibold'>Daily Job Log</p>
            {!isMobileView && me?.scheduler?.length > 1 &&
              <SchedulerLocationDropdown
                value={selectedScheduler?.location?.id}
                placeholder={'Schedule Location:'}
                options={locationDropdownOptions}
                onChange={(e) => {
                  const selectedLocationId = parseInt(e.target.value);
                  const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
                  if (matchingScheduler) {
                    cookies.set('schedulerId', matchingScheduler.id.toString());
                    setSelectedScheduler(matchingScheduler);
                  }
                }}
                role='scheduler'
              />
            }
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-6 p-6 lg:p-0 lg:border-b'>
          <div className='lg:pl-6 lg:py-6'>
            <SchedulerJobLogDateSelector askIfContinueDate={askIfContinueDate} />
          </div>
          <div className='lg:bg-navy-bright/10 lg:py-6 lg:pl-6 lg:w-full lg:border-l'>
            {todaysCalendarJobs[0].id
              ?
              <>
                <JobSelector
                  todaysJobs={todaysCalendarJobs}
                  selectedCalendarJob={selectedCalendarJob}
                  askIfContinue={askIfContinue}
                  language={language}
                />
              </>
              :
              <p>No jobs for this day.</p>
            }
          </div>

        </div>
        {
          selectedCalendarJob?.id //Render the rest of the page only if there is an available calendar job for the day.  (If there are calendar jobs for the day, by default the first one in the array is set as the selectedCalendarJob so the rest of the page will render)
            ?
            <div className='contents lg:flex lg:flex-col lg:gap-6 lg:p-6'>

              <div className='flex justify-between'>
                <div>
                  <p className='text-xl text-dark-blue font-bold'>{selectedCalendarJob?.job?.jobName}</p>
                  <p className='text-gray-blue'>{selectedCalendarJob?.job?.address}</p>
                </div>

                <div className='flex flex-col md:flex-row items-start gap-4'>
                  <button
                    className={`flex justify-center bg-white text-dark-blue border border-dark-blue rounded-md py-2 px-4 w-46 ${pdfDisabled ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
                    onClick={() => viewPDF(selectedCalendarJob)}
                    disabled={pdfDisabled}
                  >View PDF</button>
                  {selectedCalendarJob.jobLog ?
                    <UploadToOneDriveButton
                      selectedScheduler={selectedScheduler}
                      PDFTemplate={<JobLogPDFTemplate calendarJob={selectedCalendarJob} />}
                      selectedJob={selectedJob}
                      isBreakdown={false}
                      selectedCalendarJob={selectedCalendarJob}
                      isEditing={isEditing}
                    />
                    :
                    <></>
                  }
                </div>
              </div>
              {selectedCalendarJob.jobLog?.id  //render the rest of the form only if a job log exists for the job.  If one doesn't, then a button shows which says "Create Job Log"
                ?
                <>
                  <div className='flex'>
                    <div className='w-1/2'>
                      <p className='text-sm text-gray-blue'>Date</p>
                      <p className='font-semibold text-dark-blue'>{selectedCalendarJob?.scheduledDate?.substring(5, 7).concat('.' + selectedCalendarJob?.scheduledDate?.substring(8, 10) + '.' + selectedCalendarJob?.scheduledDate?.substring(0, 4))
                      }</p>
                    </div>
                    <div className='w-1/2'>
                      <p className='text-sm text-gray-blue'>Foreman</p>
                      {noAvailableForemen &&
                        <p className='text-sm py-1 text-gunite-red'>*at least one Foreman must exist to choose from</p>
                      }

                      {isChangingForeman && !noAvailableForemen
                        ?
                        <ForemanSelector setIsChangingForeman={setIsChangingForeman} noAvailableForemen={noAvailableForemen}
                          setNoAvailableForemen={setNoAvailableForemen} />
                        :
                        <div className='flex'>
                          <p className='font-semibold text-dark-blue w-[160px]'>{selectedForeman.name}</p> {/*causes an error without optional chaining for calendar jobs that don't have a jobLog*/}
                          {isEditing && !noAvailableForemen
                            ?
                            <button onClick={() => setIsChangingForeman(true)}>
                              <p className='text-sm italic text-gray-blue hover:underline'>change foreman</p>
                            </button>

                            :
                            null
                          }
                        </div>
                      }
                    </div>
                  </div>
                  <form className='flex flex-col gap-2'>
                    <button
                      className={`w-full p-4 font-semibold rounded text-lg ${isEditing ? 'bg-dark-blue text-white' : 'bg-black/10 text-black/40'}`}
                      disabled={!isEditing}
                      onClick={(e) => {
                        e.preventDefault()
                        crewMemberOrDriver.current = 'crewMember'
                        modalMethods.setContent(<SelectPersonnel
                          personnel={locationCrewMembers}
                          jobPersonnel={selectedCrewMembers}
                          setJobPersonnel={setSelectedCrewMembers}
                          allPersonnel={allPersonnel}
                          deletedPersonnel={deletedLocationCrewMembers}
                          setDeletedPersonnel={setDeletedLocationCrewMembers}
                          crewMembersOrDrivers={'crewMembers'}
                          personnelLocation={personnelLocation}
                          updateLocationPersonnel={updateLocationPersonnel}
                        />)
                        modalMethods.open()

                      }}>Select Crew Members</button>
                    {selectedCalendarJob.job.jobType === 'gunite'
                      ?
                      <button
                        className={`w-full p-4 font-semibold rounded text-lg ${isEditing ? 'bg-dark-blue text-white' : 'bg-black/10 text-black/40'}`}
                        disabled={!isEditing}
                        onClick={(e) => {
                          e.preventDefault()
                          crewMemberOrDriver.current = 'driver'
                          modalMethods.setContent(<SelectPersonnel
                            personnel={locationDrivers}
                            jobPersonnel={selectedDrivers}
                            setJobPersonnel={setSelectedDrivers}
                            allPersonnel={allPersonnel}
                            deletedPersonnel={deletedLocationDrivers}
                            setDeletedPersonnel={setDeletedLocationDrivers}
                            crewMembersOrDrivers={'drivers'}
                            personnelLocation={personnelLocation}
                            updateLocationPersonnel={updateLocationPersonnel}
                          />)
                          modalMethods.open()
                        }}
                      >Select Drivers</button>
                      :
                      null
                    }
                    <p>Selected Crew Members: {selectedCrewMembers.map((object) => object.user.name).join(', ')}</p>
                    {selectedCalendarJob.job.jobType === 'gunite'
                      ?
                      <p>Selected Drivers: {selectedDrivers.map((object) => object.user.name).join(', ')}</p>
                      :
                      null
                    }
                    <label
                      htmlFor='startTime'
                      className='text-sm text-gray-blue'
                    >Start Time</label>
                    <input
                      type='time'
                      id='startTime'
                      className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                      list='suggestedTimes'
                      value={startTime || ''}
                      onChange={(e) => {
                        e.preventDefault()
                        setStartTime(e.target.value)
                      }}
                      disabled={!isEditing}
                    />
                    <datalist id='suggestedTimes'>
                      <option value='07:00'></option>
                      <option value='08:00'></option>
                      <option value='09:00'></option>
                      <option value='10:00'></option>
                      <option value='11:00'></option>
                      <option value='12:00'></option>
                      <option value='13:00'></option>
                      <option value='14:00'></option>
                      <option value='15:00'></option>
                      <option value='16:00'></option>
                      <option value='17:00'></option>
                      <option value='18:00'></option>
                      <option value='19:00'></option>
                    </datalist>
                    <label
                      htmlFor='endTime'
                      className='text-sm text-gray-blue'
                    >End Time</label>
                    <input
                      type='time'
                      id='endTime'
                      className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                      list='suggestedTimes'
                      value={endTime || ''}
                      onChange={(e) => {
                        e.preventDefault()
                        setEndTime(e.target.value)
                      }}
                      disabled={!isEditing}
                    />
                    {yardsOrBatchesInput()}
                    {isEditing
                      ?
                      <>
                        <p className='text-sm text-gray-blue'>Photos of Completed Work</p>
                        <input
                          type='file'
                          multiple={true}
                          className='hidden'
                          onChange={handleUpload}
                          ref={hiddenFileInput}
                        />
                        <button
                          className='w-full py-10 flex flex-col gap-4 items-center rounded border-dashed border-2 border-black/30'
                          onClick={(e) => {
                            e.preventDefault()
                            hiddenFileInput.current.click()
                          }}
                        >
                          <img src='/vectors/photoUploadIcon.svg' />
                          <div>
                            <p className='font-semibold text-sm text-dark-blue tracking-wide'>Upload photo</p>
                            <p className='font-light text-gray-blue text-sm'>of a finished job</p>
                          </div>
                        </button>
                      </>
                      :
                      null
                    }
                    {selectedCalendarJob.jobLog.finishedJobPhotos.length ? <>
                      <p className='text-sm text-gray-blue'>Uploaded photos</p>
                      <div className='flex flex-wrap items-center'>
                        {selectedCalendarJob.jobLog?.finishedJobPhotos?.map((photo, i) => {
                          return <DailyJobLogPhoto apiUrl={apiUrl} fileUri={photo.fileUri} key={`pow${i}`} id={photo.id} deletePhoto={deletePhoto} isEditing={isEditing} />
                        })}
                      </div>
                    </>
                      :
                      null
                    }
                    {submitOrEditButton()}
                  </form>
                </>
                :
                <div className='flex flex-col gap-2 items-start'>
                  <p>No log exists for this job.</p>
                  <button
                    className='p-4 bg-dark-blue text-white font-semibold rounded text-xl'
                    onClick={createNewJobLog}
                  >Create job log</button>
                </div>
              }
            </div>
            :
            null
        }
      </div>
    </div>
  )
}

export default SchedulerDailyJobLog
