import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function BigSpinner() {
	return (
		<div className="flex w-full h-full justify-center items-center">
			<FontAwesomeIcon icon={faSpinner} size={'4x'} spin />
		</div>
	)
}

export default BigSpinner
